import { Col, Image, Row, Select, Tooltip } from "antd";
import UserProfileModal from "components/Profile";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logoutAction } from "redux/action/logoutAction";
import { clearRedux as clearReduxRole } from "redux/slice/roleTableSlice";
import { clearRedux as clearReduxUser } from "redux/slice/userSlice";
import UserNameBox from "../UserNameBox";
import styles from "./index.module.scss";
import "./index.scss";
import Cookies from "js-cookie";
import { checkPermission } from "utils/utils";
import { UserProfileInterface } from "interfaces/user.interface";
import { LogoutOutlined } from "@ant-design/icons";

// Website header
const Header = () => {
  // Inits
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const [organizationOptions, setOrganizationOptions] = useState<any>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>(
    localStorage.getItem("companyId") ?? ""
  );
  const [isLogoutBtnClick, setIsLogoutBtnClick] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: userData } = useSelector((state: any) => state?.userProfile);

  // Update organization options when user data changes
  useEffect(() => {
    const companies = userData?.companies?.map((company: any) => {
      return {
        companyId: company.company?.id,
        companyName: company.company?.companyName,
      };
    });
    setOrganizationOptions(companies);
  }, [userData]);

  // Logout Handler
  const logoutData = {
    email: userData?.email,
    accessToken: userData?.accessToken,
  };
  const logoutHandler = () => {
    setIsLogoutBtnClick(true);
    const data = { logoutData };
    dispatch(logoutAction(data) as any)
      .unwrap()
      .then(() => {
        Cookies.remove("accessToken");
        localStorage.clear();
        navigate("/login");
      })
      .catch(() => {
        Cookies.remove("accessToken");
        localStorage.clear();
      });
  };

  // My Profile Handler
  const myProfileHandler = () => {
    setProfileModalOpen(true);
  };

  const profileCancel = () => {
    setProfileModalOpen(false);
  };

  const organizationChangeHandler = (e: any) => {
    localStorage.setItem("companyId", e);
    setSelectedOrganization(e);
  };

  // clearing the redux when side drawer gets closed
  useEffect(() => {
    if (!isSideDrawerOpen) {
      dispatch(clearReduxUser());
      dispatch(clearReduxRole());
    }
  }, [isSideDrawerOpen]);

  const selectedCompanyPermission = useSelector(
    (state: UserProfileInterface) =>
      state?.userProfile?.data?.companies[0]?.role?.permissions
  );

  
  // Check user permissions for different features

  const isAnyUserPermission = checkPermission(selectedCompanyPermission, {
    permissionName: "Users",
    permission: ["add", "all", "edit", "view", "delete"],
  });

  const isAnyRolePermission = checkPermission(selectedCompanyPermission, {
    permissionName: "Roles",
    permission: ["add", "all", "edit", "view", "delete"],
  });

  const isAnyIntegrationPermission = checkPermission(
    selectedCompanyPermission,
    {
      permissionName: "Integrations",
      permission: ["add", "all", "edit", "view", "delete"],
    }
  );

  const isAnySubscriptionPermission = checkPermission(
    selectedCompanyPermission,
    {
      permissionName: "Subscriptions",
      permission: ["add", "all", "edit", "view", "delete"],
    }
  );

  useEffect(() => {
    if (
      location.pathname.startsWith("/settings") &&
      selectedCompanyPermission?.length
    ) {
      if (
        !isAnyIntegrationPermission &&
        !isAnyRolePermission &&
        !isAnySubscriptionPermission &&
        !isAnyUserPermission
      ) {
        navigate("/");
      }
    }
  }, [location, selectedCompanyPermission?.length]);

  // Handle navigation based on permissions
  const handleNavigatePermissionWise = () => {
    if (isAnyUserPermission) {
      navigate("/settings/users");
    } else if (isAnyRolePermission) {
      navigate("/settings/roles");
    } else if (isAnyIntegrationPermission) {
      navigate("/settings/integrations/connectedApps");
    } else if (isAnySubscriptionPermission) {
      navigate("/settings/subscription");
    }
  };

  return (
    <>
      <header className={styles["header"]}>
        <Row
          className={styles["header__wrapper"]}
          align={"middle"}
          justify={"space-between"}
        >
          <Col className={styles["header__details-left"]}>
            <div className={styles["header__details-left--logo"]}>
              <Image
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/images/animal_planet_logo.png`}
                preview={false}
                alt="group"
                crossOrigin={
                  process.env.REACT_APP_ENV === "local"
                    ? undefined
                    : "anonymous"
                }
              />
            </div>
          </Col>
          <Col className={styles["header__details-right"]}>
            {organizationOptions?.length > 0 && (
              <Select
                placeholder="Select Organization"
                className={styles["header__details-right--organization"]}
                onChange={(e) => organizationChangeHandler(e)}
                defaultValue={
                  selectedOrganization == "undefined"
                    ? "Select Organization"
                    : selectedOrganization
                }
              >
                {organizationOptions?.map((company: any) => (
                  <Select.Option
                    value={company?.companyId}
                    key={company?.companyId}
                  >
                    {company?.companyName}
                  </Select.Option>
                ))}
              </Select>
            )}

            {(isAnyIntegrationPermission ||
              isAnyRolePermission ||
              isAnySubscriptionPermission ||
              isAnyUserPermission) && (
              <Tooltip placement="bottom" title={`Setting`}>
                <div
                  className={styles["header__details-right--settings"]}
                  onClick={() => {
                    handleNavigatePermissionWise();
                  }}
                >
                  <Image
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/images/settings.png`}
                    preview={false}
                    alt="group"
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                  />
                </div>
              </Tooltip>
            )}

            <Tooltip placement="bottom" title={`Profile`}>
              <div
                className={styles["header__details-right--user"]}
                onClick={myProfileHandler}
              >
                <div className={styles["header__details-right--user-logo"]}>
                  <UserNameBox
                    name={`${userData?.firstName} ${userData?.lastName}`}
                    imageUrl={
                      userData?.profileImg &&
                      `${process.env.REACT_APP_AWS_BASE_URL}${userData?.profileImg}`
                    }
                  />
                </div>
                <div className={styles["header__details-right--user-details"]}>
                  <p className={styles["header__details-right--user-name"]}>
                    {userData?.firstName} {userData?.lastName}
                  </p>
                  <p className={styles["header__details-right--user-profile"]}>
                    My Profile
                  </p>
                </div>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`Log out`}>
              <div className={styles["header__details-right--user-logout"]}>
                <LogoutOutlined
                  style={{
                    cursor: isLogoutBtnClick ? "not-allowed" : "pointer",
                    pointerEvents: isLogoutBtnClick ? "none" : "auto",
                    fontSize:23,
                    color: "#727272",
                  }}
                  onClick={logoutHandler}
                />
              </div>
            </Tooltip>
          </Col>
        </Row>
      </header>

      {isProfileModalOpen && (
        <UserProfileModal
          isProfileModalOpen={isProfileModalOpen}
          handleCancel={profileCancel}
        />
      )}
    </>
  );
};

export default Header;
