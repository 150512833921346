import { FC } from "react";
import { PasswordRulesProps } from "./types";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const PasswordValidation: FC<PasswordRulesProps> = (props) => {
  const { minLength, hasSpecialAndNumber } = props;

  return (
    <div>
      <div
        style={{
          color:
            minLength === true
              ? "green"
              : minLength === false
              ? "#ff4d4f"
              : "#b4b3b3",
        }}
      >
        {minLength === true ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : minLength === false ? (
          <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
        ) : (
          <InfoCircleOutlined style={{ color: "#b4b3b3" }} />
        )}
        <span style={{ marginLeft: "5px" }}>More than 8 characters</span>
      </div>
      <div
        style={{
          color:
            hasSpecialAndNumber === true
              ? "green"
              : hasSpecialAndNumber === false
              ? "#ff4d4f"
              : "#b4b3b3",
        }}
      >
        {hasSpecialAndNumber === true ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : hasSpecialAndNumber === false ? (
          <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
        ) : (
          <InfoCircleOutlined style={{ color: "#b4b3b3" }} />
        )}
        <span style={{ marginLeft: "5px" }}>
          Includes capital and lowercase characters, special characters, and
          numbers
        </span>
      </div>
    </div>
  );
};

export default PasswordValidation;
