import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchProfileAction } from "redux/action/profileAction";
import { getCompanies } from "redux/slice/companySlice";
import { AppDispatch } from "redux/store";
import Cookies from "js-cookie";
import { capitalizeFirstLetter } from "utils/Common";

export const AuthLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const authpath  = ['/login','/reset-password','/forgot-password','/register'];

  // // To change the title.
  const { pathname } = useLocation();
  useEffect(() => {
    const token = Cookies.get("accessToken");
    const check = window.location.pathname.split("/").reverse()
    const url = check.filter(
      (item: any) =>
        item !== "localhost:3000" && item !== "" && item !== "http:"
    );
    if (url.length !== 0) {
      if (url.length < 3) {
        if (url.length < 2) {

          document.title = `${capitalizeFirstLetter(url[0]) + " | " + "Fintech App"}`
        }else{
          document.title = `${capitalizeFirstLetter(url[0]) + " | " + capitalizeFirstLetter(url[1]) + " | " + "Fintech App"}`;
        }
      }
      else {
        document.title = `${capitalizeFirstLetter(url[1]) + " | " + capitalizeFirstLetter(url[0]) + " | " + "Fintech App"}`;
      }
    }
    else {
      document.title = `Fintech App`
    }

    if((!authpath.includes(path) && !token ))
    {
      navigate('/login');
    }

  }, [pathname]);

  const path = window.location.pathname;
  useEffect(() => {
    const token = Cookies.get("accessToken");
    if (token) {
      dispatch(fetchProfileAction())
        .unwrap()
        .then((res) => {
          dispatch(getCompanies(res));
        })
        .catch((error: any) => {
          if (error.responseStatus === 401) {
            navigate("/access-denied");
          } else if (
            !(path === "/forgot-password" || path === "/reset-password")
          ) {
            navigate("/login"); //For Verify Email
          }
        });

      if (path === "/login") {
        navigate("/");
      }
    } else {
      if (!(path === "/forgot-password" || path === "/reset-password")) {
        navigate("/login"); //For Verify Email
      }
    }
  }, []);
  

  return <Outlet />;
};
