/* eslint-disable react-hooks/rules-of-hooks */
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";
import { UserProfileInterface } from 'interfaces/user.interface';
import { FC, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { integrationsPaymentCards } from "utils/staticObject";
import { checkPermission } from 'utils/utils';
const PaymentGatewayTable: FC = () => {

   // Get the current location
   const location = useLocation();

   // Initialize the navigation function
   const navigate = useNavigate();
 
   // Get user's selected company permissions from Redux state
  const selectedCompanyPermission = useSelector((state: UserProfileInterface) => state?.userProfile?.data?.companies[0]?.role?.permissions)

   // Check if the user has view permission for integrations
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: 'Integrations',
    permission: ['view'],
  });

  useEffect(() => {
     // Check if the location path starts with '/settings/integrations' and the user has permissions
    if (location.pathname.startsWith('/settings/integrations') && selectedCompanyPermission?.length) {
       // If the user doesn't have view permission, navigate to the home page
      if (!hasViewPermission) {
        navigate('/')
      }
    }

  }, [location, selectedCompanyPermission?.length]);
  // JSX
  return <DynamicCategoryTable dataSource={integrationsPaymentCards} type={integrationsPaymentCards[0]?.type} />;
};

export default PaymentGatewayTable;
