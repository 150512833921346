import { Select } from "antd";
import { useState } from "react";
import { postUnifiedApi } from "redux/apis";
import { invalidText, toastText } from "utils/utils";
import AddAccountModal from "../AddAccountModal";
import styles from "./index.module.scss";

interface OptionInterface {
  label: string;
  value: string;
}

interface Props {
  selectedConnection: OptionInterface | null;
  handleConnection: (value: OptionInterface | null) => void;
  handleAccountingApp: any;
  handleChannelType: any;
  selectedChannel: any;
  connections: any;
  selectedApp: any;
  accountingApps: any;
  accountsHandler: any;
  isAccountLoading: boolean;
}

const channelTypes = [
  {
    value: "Accounting",
    label: "Accounting",
  },
  {
    value: "CRM",
    label: "CRM",
  },
  {
    value: "Ecommerce",
    label: "E-commerce",
  },
  {
    value: "ERP",
    label: "ERP",
  },
  {
    value: "Marketplace",
    label: "Marketplace",
  },
  {
    value: "Payment",
    label: "Payment Gateway",
  },
];

const SearchAndFilter = (props: Props) => {
  const {
    selectedConnection,
    handleConnection,
    // handleAccountingApp,
    handleChannelType,
    selectedChannel,
    connections,
    selectedApp,
    accountingApps,
    accountsHandler,
    isAccountLoading,
  } = props;

  const [isNewAccountModalOpen, setIsNewAccountModalOpen] = useState(false);
  const [isAccLoading, setIsAccLoading] = useState(false);

  const handleModalClose = () => {
    setIsNewAccountModalOpen(false);
  };

  const handleSubmit = async (values: any) => {
    if (values) {
      try {
        setIsAccLoading(true);

        if (values.description && invalidText(values.description)) {
          delete values["description"];
        }
        if (values.accountSubType && invalidText(values.accountSubType)) {
          delete values["accountSubType"];
        }
        if (values.bankAccountNumber && invalidText(values.bankAccountNumber)) {
          delete values["bankAccountNumber"];
        }

        await postUnifiedApi("/accounting/v1/accounts", values, {
          connectionId: selectedConnection,
          tenantId: localStorage.getItem("companyId"),
        });
        handleModalClose();
        accountsHandler();
        toastText(`Accounts created successfully`, "success");
      } catch (err: any) {
        let message = `Something went wrong in  creating  account.`;
        if (err?.response?.data?.message) {
          message = err.response?.data?.message;
        }
        toastText(message, "error");
      } finally {
        setIsAccLoading(false);
      }
    }
  };

  return (
    <>
      <div className={styles["search-and-filter"]}>
        <div className={styles["search-and-filter-left"]}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Channel Types"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={channelTypes}
            onChange={handleChannelType}
            value={selectedChannel}
          />
          {/* <Select
            style={{ width: 200 }}
            placeholder="Apps"
            optionFilterProp="children"
            disabled={accountingApps?.length === 0}
            options={accountingApps}
            onChange={handleAccountingApp}
            value={selectedApp}
          /> */}
          <Select
            style={{ width: 400 }}
            placeholder="Company"
            optionFilterProp="children"
            disabled={accountingApps?.length === 0}
            options={connections}
            onChange={handleConnection}
            value={selectedConnection}
          />
          <button
            onClick={() => accountsHandler()}
            type="button"
            className={`button pointer ${
              (!selectedChannel ||
                isAccountLoading ||
                isAccLoading ||
                !selectedApp ||
                !selectedConnection) &&
              "pointer-event-none"
            }`}
          >
            Fetch Accounts
          </button>
        </div>
        <div className={styles["search-and-filter-left"]}>
          <button
            onClick={() => setIsNewAccountModalOpen(true)}
            type="button"
            className={`button pointer ${
              (!selectedChannel ||
                isAccLoading ||
                !selectedApp ||
                !selectedConnection) &&
              "pointer-event-none"
            }`}
          >
            {isAccLoading ? (
              <img src="/assets/gifs/loading.gif" height={30} width={30} />
            ) : (
              "Add Account"
            )}
          </button>
        </div>
      </div>
      {isNewAccountModalOpen && (
        <AddAccountModal
          handleModalClose={handleModalClose}
          handleSubmit={handleSubmit}
          isNewAccountModalOpen={isNewAccountModalOpen}
          selectedAccount={null}
          isLoading={isAccLoading}
          selectedApp={selectedApp}
        />
      )}
    </>
  );
};

export default SearchAndFilter;
