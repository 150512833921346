import { CloseOutlined } from "@ant-design/icons";
import { DatePicker, Input, Select, Table, Tooltip } from "antd";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteUnifiedApi, postUnifiedApi, putUnifiedApi } from "redux/apis";
import { DeleteActionSvg, EditActionSvg } from "utils/svgs";
import { invalidText, toastText } from "utils/utils";
import AddAccountModal from "../AddAccountModal";
import "./index.module.scss";
import styles from "./index.module.scss";

interface OptionInterface {
  label: string;
  value: string;
}

interface QBOAccount {
  key?: number | string;
  accountId: string;
  accountName: string;
  accountType: string;
  isActive: boolean;
  accountCode: string;
  classification: string;
  description: string;
  currency: string;
  modifiedDate: string;
  bankAccountNumber: string;
  isBankAccount: boolean;
  accountSubType: string;
}

interface Sorting {
  sortByFieldName: string;
  sortFieldValue: string | null;
}

interface Props {
  selectedConnection: OptionInterface | null;
  handleConnection: (value: OptionInterface | null) => void;
  selectedApp: string | null;
  accountsHandler: (values?: Sorting) => void;
  isAccountLoading: boolean;
  filterHandler: any;
  filterValue: {
    filterFieldName: string | null;
    filterFieldValue: string;
    operator: null | string;
  };
  removeHandler: () => void;
}

const DynamicTable = (props: Props) => {
  const {
    selectedConnection,
    accountsHandler,
    isAccountLoading,
    filterHandler,
    filterValue,
    selectedApp,
    removeHandler,
  } = props;

  const { data } = useSelector((state: any) => state.accounts);

  const { Column } = Table;

  const [accounts, setAccounts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<QBOAccount | null>(
    null
  );
  const [isAccLoading, setIsAccLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<any>({});

  useEffect(() => {
    if (data && data?.length > 0) {
      const finalData = data.map((acc: QBOAccount) => {
        console.log("ACC: ", acc);
        return {
          key: acc.accountId,
          accountId: acc.accountId,
          accountName: acc.accountName,
          accountCode: acc.accountCode,
          accountType: acc.accountType,
          accountSubType: acc.accountSubType,
          description: acc.description,
          bankAccountNumber: acc.bankAccountNumber,
          isActive: acc?.isActive,
          classification: acc.classification,
          currency: acc.currency,
          modifiedDate: acc?.modifiedDate ? moment(acc.modifiedDate).format("MM/DD/YYYY HH:mm:ss") : '',
          isBankAccount: acc.isBankAccount,
        };
      });
      setAccounts(finalData);
    } else {
      setAccounts([]);
    }
  }, [data]);

  let filterOptions: any = [];

  if (selectedApp) {
    filterOptions = [
      {
        label: "Id",
        value: "accountId",
      },
      {
        label: "Code",
        value: "accountCode",
      },
      {
        label: "Name",
        value: "accountName",
      },
      {
        label: "Description",
        value: "description",
      },
      {
        label: "Currency",
        value: "currency",
      },
      {
        label: "Bank Account Number",
        value: "bankAccountNumber",
      },
      {
        label: "Type",
        value: "accountType",
      },
      {
        label: "Sub Type",
        value: "accountSubType",
      },
      {
        label: "Is Bank Account",
        value: "isBankAccount",
      },
      {
        label: "Status",
        value: "isActive",
      },
      {
        label: "Modified Date",
        value: "modifiedDate",
      },
      {
        label: "Has Sub Account",
        value: "isSubAccount",
      },
    ];
  }

  const handleModalClose = () => {
    setSelectedAccount(null);
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const removeSelectedAccount = () => {
    setSelectedAccount(null);
  };

  const editAccountHandler = (account: QBOAccount) => {
    setSelectedAccount(account);
    setIsModalOpen(true);
  };

  const deleteAccountHandler = (account: QBOAccount) => {
    setSelectedAccount(account);
    setIsDeleteModalOpen(true);
  };

  const handleSubmit = async (values: any) => {
    if (values) {
      try {
        setIsAccLoading(true);

        if (invalidText(values.description)) {
          delete values["description"];
        }
        if (invalidText(values.accountSubType)) {
          delete values["accountSubType"];
        }
        if (invalidText(values.bankAccountNumber)) {
          delete values["bankAccountNumber"];
        }
        if (invalidText(values.currency)) {
          delete values["currency"];
        }
        if (
          (values.isActive == true || values.isActive == false) &&
          selectedAccount?.isActive === values.isActive
        ) {
          delete values["isActive"];
        }
        if (
          (values.isActive == true || values.isActive == false) &&
          selectedAccount?.isActive !== values.isActive &&
          selectedApp === "Xero"
        ) {
          const _values = {
            isActive: values["isActive"],
          };
          values = _values;
        }
        if (selectedAccount) {
          values["accountId"] = selectedAccount?.accountId;
          const acc: any = await putUnifiedApi(
            "/accounting/v1/accounts",
            values,
            {
              connectionId: selectedConnection,
              tenantId: localStorage.getItem("companyId"),
            }
          );
          const _newAccounts: QBOAccount[] = accounts;
          const index = _newAccounts.findIndex(
            (item: any) => item.accountId === values.accountId
          );
          _newAccounts[index] = {
            key: acc?.data?.data.accountId,
            accountId: acc?.data?.data.accountId,
            accountName: acc?.data?.data.accountName,
            accountCode: acc?.data?.data.accountCode,
            accountType: acc?.data?.data.accountType,
            accountSubType: acc?.data?.data.accountSubType,
            description: acc?.data?.data.description,
            bankAccountNumber: acc?.data?.data.bankAccountNumber,
            isActive: acc?.data?.data?.isActive,
            classification: acc?.data?.data.classification,
            currency: acc?.data?.data.currency,
            modifiedDate: moment(acc?.data?.data.modifiedDate).format(
              "MM/DD/YYYY HH:mm:ss"
            ),
            isBankAccount: acc.isBankAccount,
          };
        } else {
          await postUnifiedApi("/accounting/v1/accounts", values, {
            connectionId: selectedConnection,
            tenantId: localStorage.getItem("companyId"),
          });
        }
        handleModalClose();
        // checkFilterValues();
        // accountsHandler();
        // removeHandler()
        toastText(
          `Accounts ${selectedAccount ? "updated" : "created"} successfully`,
          "success"
        );

        removeSelectedAccount();
      } catch (err: any) {
        let message = `Something went wrong in ${
          selectedAccount ? "editing" : "creating"
        } account.`;
        if (err?.response?.data?.message) {
          message = err.response?.data?.message;
        }
        toastText(message, "error");
      } finally {
        setIsAccLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    setIsDeleteLoading(true);
    try {
      await deleteUnifiedApi(
        `/accounting/v1/accounts/${selectedAccount?.accountId}`,
        {
          connectionId: selectedConnection,
          tenantId: localStorage.getItem("companyId"),
        }
      );

      const _newAccounts: QBOAccount[] = accounts;
      const __newAccounts: any = _newAccounts.filter(
        (item: any) => item.accountId !== selectedAccount?.accountId
      );
      handleModalClose();
      setAccounts(__newAccounts);
      toastText("Accounts deleted successfully", "success");
    } catch (err: any) {
      let message = "Something went wrong in deleting account.";
      if (err?.response?.data?.message) {
        message = err.response?.data?.message;
      }
      toastText(message, "error");
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const handleTable = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
    try {
      const sortValue =
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : null;

      const params = {
        sortByFieldName: sorter.field,
        sortFieldValue: sortValue,
      };
      if (sortValue === null) {
        delete params["sortByFieldName"];
      }
      accountsHandler(params);
    } catch (err) {
      toastText("Something went wrong in sorting.", "error");
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Select
          style={{ width: 200 }}
          placeholder="Filter"
          options={filterOptions}
          onChange={(value: string) => {
            filterHandler("filterFieldName", value);
          }}
          value={filterValue.filterFieldName}
        />
        {filterValue.filterFieldName === "modifiedDate" ? (
          <DatePicker
            style={{ width: 200 }}
            onChange={(e: any) => {
              filterHandler("filterFieldValue", e?.$d);
            }}
            showTime
            clearIcon={false}
          />
        ) : (
          <Input
            className="qbo-account-form__field-input"
            placeholder="Search"
            size="middle"
            onChange={(e: any) => {
              filterHandler("filterFieldValue", e.target.value);
            }}
            value={filterValue.filterFieldValue}
            style={{ width: "200px" }}
          />
        )}
        {filterValue.filterFieldName === "modifiedDate" && (
          <Select
            style={{ width: 200 }}
            placeholder="Operator"
            options={[
              {
                label: "=",
                value: "=",
              },
              {
                label: ">",
                value: ">",
              },
              {
                label: "<",
                value: "<",
              },
              {
                label: ">=",
                value: ">=",
              },
              {
                label: "<=",
                value: "<=",
              },
            ]}
            onChange={(value: string) => {
              filterHandler("operator", value);
            }}
            value={filterValue.operator}
          />
        )}
        <Tooltip title="Clear Filter">
          <span onClick={removeHandler} className="pointer">
            <CloseOutlined style={{ fontSize: "20px", color: "grey" }} />
          </span>
        </Tooltip>
        {/* <button className="button pointer" onClick={removeHandler}>
          X
        </button> */}
      </div>
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <Table
          dataSource={accounts}
          pagination={false}
          scroll={{ y: "calc(80vh - 230px)", x: "60vh" }}
          loading={isAccountLoading}
          onChange={handleTable}
        >
          <Column
            title="Name"
            dataIndex="accountName"
            key="accountName"
            width={"10%"}
            className="bg-white"
            sorter={(a: any, b: any) =>
              a?.accountName?.localeCompare(b.accountName)
            }
            sortOrder={
              sortedInfo?.columnKey === "accountName" && sortedInfo?.order
            }
          />
          <Column
            title="Code"
            dataIndex="accountCode"
            key="accountCode"
            width={"6%"}
            className="bg-white"
            sorter={(a: any, b: any) =>
              a?.accountCode?.localeCompare(b.accountCode)
            }
            sortOrder={
              sortedInfo?.columnKey === "accountCode" && sortedInfo?.order
            }
          />
          <Column
            title="Type"
            dataIndex="accountType"
            key="accountType"
            width={"10%"}
            className="bg-white"
            sorter={(a: any, b: any) =>
              a?.accountType?.localeCompare(b.accountType)
            }
            sortOrder={
              sortedInfo?.columnKey === "accountType" && sortedInfo?.order
            }
          />
          <Column
            title="Sub Type"
            dataIndex="accountSubType"
            key="accountSubType"
            width={"10%"}
            className="bg-white"
            sorter={(a: any, b: any) =>
              a?.accountSubType?.localeCompare(b.accountSubType)
            }
            sortOrder={
              sortedInfo?.columnKey === "accountSubType" && sortedInfo?.order
            }
          />
          <Column
            title="Is Active"
            dataIndex="isActive"
            key="isActive"
            width={"4%"}
            className="bg-white"
            render={(value) => {
              return <span>{value?.toString()?.toUpperCase()}</span>;
            }}
          />
          <Column
            title="Currency"
            dataIndex="currency"
            key="currency"
            width={"8%"}
            className="bg-white"
            sorter={(a: any, b: any) => a?.currency?.localeCompare(b.currency)}
            sortOrder={
              sortedInfo?.columnKey === "currency" && sortedInfo?.order
            }
          />
          <Column
            title="Bank Acc Number"
            dataIndex="bankAccountNumber"
            key="bankAccountNumber"
            width={"12%"}
            className="bg-white"
            sorter={(a: any, b: any) =>
              a?.bankAccountNumber?.localeCompare(b.bankAccountNumber)
            }
            sortOrder={
              sortedInfo?.columnKey === "bankAccountNumber" && sortedInfo?.order
            }
          />
          <Column
            title="Is Bank Account"
            dataIndex="isBankAccount"
            key="isBankAccount"
            width={"6%"}
            className="bg-white"
            render={(value: string | boolean) => {
              // Display connected app logo
              return <span>{value?.toString()?.toUpperCase()}</span>;
            }}
          />
          <Column
            title="Modified Date"
            dataIndex="modifiedDate"
            key="modifiedDate"
            width={"10%"}
            className="bg-white"
            sorter={(a: any, b: any) =>
              a?.modifiedDate?.localeCompare(b.modifiedDate)
            }
            sortOrder={
              sortedInfo?.columnKey === "modifiedDate" && sortedInfo?.order
            }
          />
          <Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            width={"8%"}
            className="bg-white"
            render={(_, record: QBOAccount) => {
              // Display connected app logo
              return (
                <div className={styles["icons"]}>
                  <span
                    onClick={() => editAccountHandler(record)}
                    className="pointer"
                  >
                    <EditActionSvg />
                  </span>
                  <span
                    onClick={() => deleteAccountHandler(record)}
                    className="pointer"
                  >
                    <DeleteActionSvg />
                  </span>
                </div>
              );
            }}
          />
        </Table>
        {isModalOpen && (
          <AddAccountModal
            handleModalClose={handleModalClose}
            handleSubmit={handleSubmit}
            isNewAccountModalOpen={isModalOpen}
            selectedAccount={selectedAccount}
            isLoading={isAccLoading}
            removeSelectedAccount={removeSelectedAccount}
            selectedApp={selectedApp}
          />
        )}
        {isDeleteModalOpen && (
          <ConfirmDelete
            isModalOpen={isDeleteModalOpen}
            handleCancel={handleModalClose}
            deleteHandler={handleDelete}
            handleOk={handleModalClose}
            isAddUserLoading={isDeleteLoading}
          />
        )}
      </div>
    </div>
  );
};

export default DynamicTable;
