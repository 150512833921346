import IntegrationLayout from "layouts/Settings/Integration";
import { TableActionHeader } from "components/Global";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function IntegrationPage() {
  // Get the current pathname and initialize selectedSidebar with the last segment.
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);

  const navigate = useNavigate();

  // Handle sidebar selection change.
  const sideBarChangeHandler = (selectedValue: any) => {
    navigate(selectedValue.key);
  };

  // Initialize selectedSidebar based on the current pathname.
  const initialFunction = () => {
    const splitArray = pathname.split("/");
    setSelectedSidebar(splitArray[splitArray.length - 1]);
  };

  // Call initialFunction when the component mounts or the window location changes.
  useEffect(() => {
    initialFunction();
  }, [window.location.href]);

  // Render the component.
  return (
    <div>
      <TableActionHeader title={"Integrations"}></TableActionHeader>
      <IntegrationLayout
        onSideBarChange={sideBarChangeHandler}
        selectedSidebar={selectedSidebar}
      >
        <Outlet />
      </IntegrationLayout>
    </div>
  );
}
