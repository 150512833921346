import SearchAndFilter from "components/settings/Configurations/SearchAndFilter";
import DynamicTable from "components/settings/Configurations/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsAction } from "redux/action/accountsAction";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { AppDispatch } from "redux/store";
import { channelTypeCards } from "utils/staticObject";
import { toastText } from "utils/utils";

interface OptionInterface {
  label: string;
  value: string;
}

const Configurations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedConnection, setSelectedConnection] =
    useState<OptionInterface | null>(null);
  const [selectedChannel, setSelectedChannel] =
    useState<OptionInterface | null>(null);
  const [connections, setConnections] = useState<any>([]);
  const [selectedApp, setSelectedApp] = useState<string | null>(null);
  const [accountingApps, setAccountingApps] = useState<any>([]);
  const [isAccountLoading, setIsAccountLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    filterFieldName: null,
    filterFieldValue: "",
    operator: null,
  });

  const activeServices = useSelector((state: any) => state.ActiveServices.data);

  const handleChannelType = (value: OptionInterface) => {
    setSelectedChannel(value);

    const _accountingApps =
      channelTypeCards?.length > 0 &&
      channelTypeCards
        ?.filter((channelType) => channelType.type === value)
        .map((channel) => {
          return {
            label: channel.titleKey,
            value: channel.titleKey,
          };
        });

    const _connections = activeServices
      .filter((service: any) => service.channelType == value)
      .map((connection: any) => {
        return {
          label: `${connection.companyName} (${connection.channelName})`,
          value: connection.id,
        };
      });
    setAccountingApps(_accountingApps);
    setConnections(_connections);
    // setSelectedApp(null);
    // handleConnection(null);
  };

  const handleAccountingApp = (value: string) => {
    setSelectedApp(value);
    const _connections =
      activeServices?.length &&
      activeServices
        ?.filter((service: any) => service.channelName === value)
        .map((connection: any) => {
          return {
            label: `${connection.companyName} (${connection.channelName})`,
            value: connection.id,
          };
        });
    setConnections(_connections);
    handleConnection(null);
  };

  const handleConnection = (value: OptionInterface | null) => {
    const connection = activeServices.find(
      (service: any) => service.id === value
    );
    setSelectedApp(connection?.channelName);
    setSelectedConnection(value);
  };

  const accountsHandler = async (newParams?: any) => {
    try {
      setIsAccountLoading(true);

      let params = {
        ...filterValue,
      };
      if (newParams) {
        params = {
          ...params,
          ...newParams,
        };
      }
      const query = {
        params: params,
        headers: {
          connectionId: selectedConnection,
          tenantId: localStorage.getItem("companyId"),
        },
      };

      await dispatch(getAccountsAction(query));
    } catch (err) {
      toastText("Something went wrong in fetching accounts", "error");
    } finally {
      setIsAccountLoading(false);
      // removeHandler();
    }
  };

  const removeHandler = () => {
    setFilterValue({
      filterFieldName: null,
      filterFieldValue: "",
      operator: null,
    });
    accountsHandler({
      filterFieldName: null,
      filterFieldValue: "",
      operator: null,
    });
  };

  const filterHandler = (name: string, value: string | Date) => {
    setFilterValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    dispatch(getActiveConnectionAction());
  }, []);

  useEffect(() => {
    setFilterValue({
      filterFieldName: null,
      filterFieldValue: "",
      operator: null,
    });
  }, [selectedChannel, selectedConnection, selectedApp]);

  return (
    <div>
      <SearchAndFilter
        selectedConnection={selectedConnection}
        handleConnection={handleConnection}
        handleAccountingApp={handleAccountingApp}
        handleChannelType={handleChannelType}
        selectedChannel={selectedChannel}
        connections={connections}
        selectedApp={selectedApp}
        accountingApps={accountingApps}
        accountsHandler={accountsHandler}
        isAccountLoading={isAccountLoading}
      />
      <DynamicTable
        selectedConnection={selectedConnection}
        handleConnection={handleConnection}
        selectedApp={selectedApp}
        accountsHandler={accountsHandler}
        isAccountLoading={isAccountLoading}
        filterHandler={filterHandler}
        filterValue={filterValue}
        removeHandler={removeHandler}
      />
      {/* <MappingBox
        selectedConnection={selectedConnection}
        handleConnection={handleConnection}
        selectedApp={selectedApp}
        accountsHandler={accountsHandler}
      />  */}
    </div>
  );
};

export default Configurations;
