import { createAsyncThunk } from '@reduxjs/toolkit';
import { postApi } from 'redux/apis';

export const netSuiteAction :any = createAsyncThunk(
	'integration/netsuite',
	async ( values:any,{ rejectWithValue }) => {
		try {
            
			const response = await postApi(`/netsuite/connect`,values);
			
			
			return response.data.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);