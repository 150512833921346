// Define channel names
export const Channels = {
  FRESH_BOOKS: "FreshBooks",
  QBO: "Quickbooks Online",
  SAGE_INTACCT: "Sage Intacct",
  WOO_COMMERCE: "Woo Commerce",
  SHOP_WARE: "ShopWare",
  SHOPIFY: "Shopify",
  MAGENTO: "Magento",
  SALESFORCE: "Salesforce",
  XERO: "Xero",
  STRIPE: "Stripe",
  ZOHO_CRM: "Zoho CRM",
  BUSINESS_CENTRAL: "BusinessCentral",
  ZOHO_BOOK: "ZohoBook",
  CLEAR_BOOK: "ClearBook",
  NETSUITE: "Netsuite",
  BIG_COMMERCE: "Big Commerce",
  MS_DYNAMIC: "MsDynamic",
  AUTHORIZENET: "Authorize.net",
  QBD: "Quickbooks Desktop",
};

// Define default roles
export const defaultRoles = ["Accountant", "Read Only", "Company Admin"];

// Define page size options with comments
export const pageSizeOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
];

// Define page size options for paging
export const pageSizeOptionsPaging = [10, 20, 50, 100, 200];

export const DetailType = {
  Expense: [
    { label: "Advertising Promotional", value: "AdvertisingPromotional" },
    { label: "Bad Debts", value: "BadDebts" },
    { label: "Bank Charges", value: "BankCharges" },
    { label: "Charitable Contributions", value: "CharitableContributions" },
    { label: "Commissions And Fees", value: "CommissionsAndFees" },
    { label: "Entertainment", value: "Entertainment" },
    { label: "Entertainment Meals", value: "EntertainmentMeals" },
    { label: "Equipment Rental", value: "EquipmentRental" },
    { label: "Finance Costs", value: "FinanceCosts" },
    { label: "Global Tax Expense", value: "GlobalTaxExpense" },
    { label: "Insurance", value: "Insurance" },
    { label: "Interest Paid", value: "InterestPaid" },
    { label: "Legal Professional Fees", value: "LegalProfessionalFees" },
    { label: "Office Expenses", value: "OfficeExpenses" },
    {
      label: "Office General Administrative Expenses",
      value: "OfficeGeneralAdministrativeExpenses",
    },
    { label: "Other Business Expenses", value: "OtherBusinessExpenses" },
    {
      label: "Other Miscellaneous Service Cost",
      value: "OtherMiscellaneousServiceCost",
    },
    { label: "Promotional Meals", value: "PromotionalMeals" },
    { label: "Rent Or Lease Of Buildings", value: "RentOrLeaseOfBuildings" },
    { label: "Repair Maintenance", value: "RepairMaintenance" },
    { label: "Shipping Freight Delivery", value: "ShippingFreightDelivery" },
    { label: "Supplies Materials", value: "SuppliesMaterials" },
    { label: "Travel", value: "Travel" },
    { label: "Travel Meals", value: "TravelMeals" },
    { label: "Utilities", value: "Utilities" },
    { label: "Auto", value: "Auto" },
    { label: "Cost Of Labor", value: "CostOfLabor" },
    { label: "Dues Subscriptions", value: "DuesSubscriptions" },
    { label: "Payroll Expenses", value: "PayrollExpenses" },
    { label: "Taxes Paid", value: "TaxesPaid" },
    {
      label: "Unapplied Cash Bill Payment Expense",
      value: "UnappliedCashBillPaymentExpense",
    },
  ],
};

export const XeroTypes = [
  {
    AccountType: "Bank",
    AccountSubtype: [],
  },
  {
    AccountType: "CURRENT",
    AccountSubtype: [],
  },
  {
    AccountType: "CURRLIAB",
    AccountSubtype: [],
  },
  {
    AccountType: "DEPRECIATN",
    AccountSubtype: [],
  },
  {
    AccountType: "DIRECTCOSTS",
    AccountSubtype: [],
  },
  {
    AccountType: "EQUITY",
    AccountSubtype: [],
  },
  {
    AccountType: "EXPENSE",
    AccountSubtype: [],
  },
  {
    AccountType: "FIXED",
    AccountSubtype: [],
  },
  {
    AccountType: "INVENTORY",
    AccountSubtype: [],
  },
  {
    AccountType: "LIABILITY",
    AccountSubtype: [],
  },
  {
    AccountType: "NONCURRENT",
    AccountSubtype: [],
  },
  {
    AccountType: "OTHERINCOME",
    AccountSubtype: [],
  },
  {
    AccountType: "OVERHEADS",
    AccountSubtype: [],
  },
  {
    AccountType: "PREPAYMENT",
    AccountSubtype: [],
  },
  {
    AccountType: "REVENUE",
    AccountSubtype: [],
  },
  {
    AccountType: "SALES",
    AccountSubtype: [],
  },
  {
    AccountType: "TERMLIAB",
    AccountSubtype: [],
  },
];

export const QuickbooksTypes = [
  {
    AccountType: "Bank",
    AccountSubtype: [
      "CashOnHand (default)",
      "Checking",
      "MoneyMarket",
      "RentsHeldInTrust",
      "Savings",
      "TrustAccounts",
      "CashAndCashEquivalents",
      "OtherEarMarkedBankAccounts",
    ],
  },
  {
    AccountType: "Other Current Asset",
    AccountSubtype: [
      "AllowanceForBadDebts",
      "DevelopmentCosts",
      "EmployeeCashAdvances (default)",
      "OtherCurrentAssets",
      "Inventory",
      "Investment_MortgageRealEstateLoans",
      "Investment_Other",
      "Investment_TaxExemptSecurities",
      "Investment_USGovernmentObligations",
      "LoansToOfficers",
      "LoansToOthers",
      "LoansToStockholders",
      "PrepaidExpenses",
      "Retainage",
      "UndepositedFunds",
      "AssetsAvailableForSale",
      "BalWithGovtAuthorities",
      "CalledUpShareCapitalNotPaid",
      "ExpenditureAuthorisationsAndLettersOfCredit",
      "GlobalTaxDeferred",
      "GlobalTaxRefund",
      "InternalTransfers",
      "OtherConsumables",
      "ProvisionsCurrentAssets",
      "ShortTermInvestmentsInRelatedParties",
      "ShortTermLoansAndAdvancesToRelatedParties",
      "TradeAndOtherReceivables",
    ],
  },
  {
    AccountType: "Fixed Asset",
    AccountSubtype: [
      "AccumulatedDepletion",
      "AccumulatedDepreciation",
      "DepletableAssets",
      "FixedAssetComputers",
      "FixedAssetCopiers",
      "FixedAssetFurniture",
      "FixedAssetPhone",
      "FixedAssetPhotoVideo",
      "FixedAssetSoftware",
      "FixedAssetOtherToolsEquipment",
      "FurnitureAndFixtures (default)",
      "Land",
      "LeaseholdImprovements",
      "OtherFixedAssets",
      "AccumulatedAmortization",
      "Buildings",
      "IntangibleAssets",
      "MachineryAndEquipment",
      "Vehicles",
      "AssetsInCourseOfConstruction",
      "CapitalWip",
      "CumulativeDepreciationOnIntangibleAssets",
      "IntangibleAssetsUnderDevelopment",
      "LandAsset",
      "NonCurrentAssets",
      "ParticipatingInterests",
      "ProvisionsFixedAssets",
    ],
  },
  {
    AccountType: "Other Asset",
    AccountSubtype: [
      "LeaseBuyout",
      "OtherLongTermAssets",
      "SecurityDeposits",
      "AccumulatedAmortizationOfOtherAssets",
      "Goodwill",
      "Licenses (default)",
      "OrganizationalCosts",
      "AssetsHeldForSale",
      "AvailableForSaleFinancialAssets",
      "DeferredTax",
      "Investments",
      "LongTermInvestments",
      "LongTermLoansAndAdvancesToRelatedParties",
      "OtherIntangibleAssets",
      "OtherLongTermInvestments",
      "OtherLongTermLoansAndAdvances",
      "PrepaymentsAndAccruedIncome",
      "ProvisionsNonCurrentAssets",
    ],
  },
  {
    AccountType: "Accounts Receivable",
    AccountSubtype: ["AccountsReceivable (default)"],
  },
  {
    AccountType: "Equity",
    AccountSubtype: [
      "OpeningBalanceEquity (default)",
      "PartnersEquity",
      "RetainedEarnings",
      "AccumulatedAdjustment",
      "OwnersEquity",
      "PaidInCapitalOrSurplus",
      "PartnerContributions",
      "PartnerDistributions",
      "PreferredStock",
      "CommonStock",
      "TreasuryStock",
      "EstimatedTaxes",
      "Healthcare",
      "PersonalIncome",
      "PersonalExpense",
      "AccumulatedOtherComprehensiveIncome",
      "CalledUpShareCapital",
      "CapitalReserves",
      "DividendDisbursed",
      "EquityInEarningsOfSubsiduaries",
      "InvestmentGrants",
      "MoneyReceivedAgainstShareWarrants",
      "OtherFreeReserves",
      "ShareApplicationMoneyPendingAllotment",
      "ShareCapital",
      "Funds",
    ],
  },
  {
    AccountType: "Expense",
    AccountSubtype: [
      "AdvertisingPromotional",
      "BadDebts",
      "BankCharges",
      "CharitableContributions",
      "CommissionsAndFees",
      "Entertainment",
      "EntertainmentMeals",
      "EquipmentRental",
      "FinanceCosts",
      "GlobalTaxExpense",
      "Insurance",
      "InterestPaid",
      "LegalProfessionalFees",
      "OfficeExpenses",
      "OfficeGeneralAdministrativeExpenses",
      "OtherBusinessExpenses",
      "OtherMiscellaneousServiceCost",
      "PromotionalMeals",
      "RentOrLeaseOfBuildings",
      "RepairMaintenance",
      "ShippingFreightDelivery",
      "SuppliesMaterials",
      "Travel (default)",
      "TravelMeals",
      "Utilities",
      "Auto",
      "CostOfLabor",
      "DuesSubscriptions",
      "PayrollExpenses",
      "TaxesPaid",
      "UnappliedCashBillPaymentExpense",
      "AmortizationExpense",
      "AppropriationsToDepreciation",
      "BorrowingCost",
      "CommissionsAndFees",
      "DistributionCosts",
      "ExternalServices",
      "ExtraordinaryCharges",
      "IncomeTaxExpense",
      "LossOnDiscontinuedOperationsNetOfTax",
      "ManagementCompensation",
      "OtherCurrentOperatingCharges",
      "OtherExternalServices",
      "OtherRentalCosts",
      "ProjectStudiesSurveysAssessments",
      "PurchasesRebates",
      "ShippingAndDeliveryExpense",
      "StaffCosts",
      "Sundry",
      "TravelExpensesGeneralAndAdminExpenses",
      "TravelExpensesSellingExpense",
    ],
  },
  {
    AccountType: "Other Expense",
    AccountSubtype: [
      "Depreciation (default)",
      "ExchangeGainOrLoss",
      "OtherMiscellaneousExpense",
      "PenaltiesSettlements",
      "Amortization",
      "GasAndFuel",
      "HomeOffice",
      "HomeOwnerRentalInsurance",
      "OtherHomeOfficeExpenses",
      "MortgageInterest",
      "RentAndLease",
      "RepairsAndMaintenance",
      "ParkingAndTolls",
      "Vehicle",
      "VehicleInsurance",
      "VehicleLease",
      "VehicleLoanInterest",
      "VehicleLoan",
      "VehicleRegistration",
      "VehicleRepairs",
      "OtherVehicleExpenses",
      "Utilities",
      "WashAndRoadServices",
      "DeferredTaxExpense",
      "Depletion",
      "ExceptionalItems",
      "ExtraordinaryItems",
      "IncomeTaxOtherExpense",
      "MatCredit",
      "PriorPeriodItems",
      "TaxRoundoffGainOrLoss",
    ],
  },
  {
    AccountType: "Cost of Goods Sold",
    AccountSubtype: [
      "EquipmentRentalCos",
      "OtherCostsOfServiceCos",
      "ShippingFreightDeliveryCos",
      "SuppliesMaterialsCogs",
      "CostOfLaborCos (default)",
      "CostOfSales",
      "FreightAndDeliveryCost",
    ],
  },
  {
    AccountType: "Accounts Payable",
    AccountSubtype: [
      "AccountsPayable (default)",
      "OutstandingDuesMicroSmallEnterprise",
      "OutstandingDuesOtherThanMicroSmallEnterprise",
    ],
  },
  {
    AccountType: "Credit Card",
    AccountSubtype: ["CreditCard (default)"],
  },
  {
    AccountType: "Long Term Liability",
    AccountSubtype: [
      "NotesPayable (default)",
      "OtherLongTermLiabilities",
      "ShareholderNotesPayable",
      "AccrualsAndDeferredIncome",
      "AccruedLongLermLiabilities",
      "AccruedVacationPayable",
      "BankLoans",
      "DebtsRelatedToParticipatingInterests",
      "DeferredTaxLiabilities",
      "GovernmentAndOtherPublicAuthorities",
      "GroupAndAssociates",
      "LiabilitiesRelatedToAssetsHeldForSale",
      "LongTermBorrowings",
      "LongTermDebit",
      "LongTermEmployeeBenefitObligations",
      "ObligationsUnderFinanceLeases",
      "OtherLongTermProvisions",
      "ProvisionForLiabilities",
      "ProvisionsNonCurrentLiabilities",
      "StaffAndRelatedLongTermLiabilityAccounts",
    ],
  },
  {
    AccountType: "Other Current Liability",
    AccountSubtype: [
      "DirectDepositPayable",
      "LineOfCredit",
      "LoanPayable",
      "GlobalTaxPayable",
      "GlobalTaxSuspense",
      "OtherCurrentLiabilities (default)",
      "PayrollClearing",
      "PayrollTaxPayable",
      "PrepaidExpensesPayable",
      "RentsInTrustLiability",
      "TrustAccountsLiabilities",
      "FederalIncomeTaxPayable",
      "InsurancePayable",
      "SalesTaxPayable",
      "StateLocalIncomeTaxPayable",
      "AccruedLiabilities",
      "CurrentLiabilities",
      "CurrentPortionEmployeeBenefitsObligations",
      "CurrentPortionOfObligationsUnderFinanceLeases",
      "CurrentTaxLiability",
      "DividendsPayable",
      "DutiesAndTaxes",
      "InterestPayables",
      "ProvisionForWarrantyObligations",
      "ProvisionsCurrentLiabilities",
      "ShortTermBorrowings",
      "SocialSecurityAgencies",
      "StaffAndRelatedLiabilityAccounts",
      "SundryDebtorsAndCreditors",
      "TradeAndOtherPayables",
    ],
  },
  {
    AccountType: "Income",
    AccountSubtype: [
      "NonProfitIncome",
      "OtherPrimaryIncome (default)",
      "SalesOfProductIncome",
      "ServiceFeeIncome",
      "DiscountsRefundsGiven",
      "UnappliedCashPaymentIncome",
      "CashReceiptIncome",
      "OperatingGrants",
      "OtherCurrentOperatingIncome",
      "OwnWorkCapitalized",
      "RevenueGeneral",
      "SalesRetail",
      "SalesWholesale",
      "SavingsByTaxScheme",
    ],
  },
  {
    AccountType: "Other Income",
    AccountSubtype: [
      "DividendIncome",
      "InterestEarned",
      "OtherInvestmentIncome (default)",
      "OtherMiscellaneousIncome",
      "TaxExemptInterest",
      "GainLossOnSaleOfFixedAssets",
      "GainLossOnSaleOfInvestments",
      "LossOnDisposalOfAssets",
      "OtherOperatingIncome",
      "UnrealisedLossOnSecuritiesNetOfTax",
    ],
  },
];
