import { Form, Input } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { singleUserInputInputWithLabelAndSvgProps } from "./types";
import phoneNumberFormatter from "helper/phoneNumberFormatter";
import PasswordValidation from "../PasswordValidation";

const InputWithLabelAndSvg: FC<singleUserInputInputWithLabelAndSvgProps> = (props) => {
  // Destructure props
  const {
    singleUserInput,
    disabled,
    setPhoneNumber,
    phoneError,
    setPhoneError,
    setIsPasswordValidate,
  } = props;

  // State for phone number
  const [num, setNum] = useState("");

  // Handler for phone number input changes
  const phoneChangeHandler = (e: any) => {
    const value = e.target.value;
    setNum(phoneNumberFormatter(value));

    // Phone number input validation and error handling
    if (value === "") {
      setPhoneError("Please enter phone number");
    } else if (value.length !== 14) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  // Update the phone number when `num` changes
  useEffect(() => {
    setPhoneNumber(num);
  }, [num]);

  // State for password input validations
  const [passwordValidations, setPasswordValidations] = useState<{
    minLength?: boolean;
    hasSpecialAndNumber?: boolean;
  }>({});
  // Handler for password input changes
  const handlePasswordChange = (password: string) => {
    setIsPasswordValidate(false);

    // Check if the password meets minimum length requirements
    const isMinLength = password.length >= 8;

    // Check if the password has special characters and numbers
    const isSpecialAndNumber = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(password);

    // Update password validations
    setPasswordValidations({
      minLength: isMinLength,
      hasSpecialAndNumber: isMinLength && isSpecialAndNumber,
    });

    // Update password validation status
    setIsPasswordValidate(isMinLength && isSpecialAndNumber);
  };

  // JSX
  return (
    <div className={styles["input-icon"]}>
      {singleUserInput.id !== "phone" ? (
        <>
          <div className={styles["input-icon__title"]}>
            {singleUserInput.svg && (
              <div className={styles["input-icon__title--svg"]}>
                {singleUserInput.svg}
              </div>
            )}
            {
              <label className={styles["input-icon__title--label"]}>
                {singleUserInput.title}{" "}
                {singleUserInput?.required && (
                  <span className="required-color">*</span>
                )}
              </label>
            }
          </div>
          <div className={styles["input-icon__form"]}>
            <Form.Item
              name={singleUserInput.name}
              rules={singleUserInput.rules}
            >
              {singleUserInput.type === "text" &&
                singleUserInput.id !== "phone" && (
                  <Input
                    placeholder={singleUserInput.placeHolder}
                    size="large"
                    className={styles["input-icon__form--input"]}
                    type={singleUserInput.type}
                    disabled={disabled ? disabled : false}
                  />
                )}

              {singleUserInput.type === "password" && (
                <Input.Password
                  placeholder={singleUserInput.placeHolder}
                  size="large"
                  className={styles["input-icon__form--input"]}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                />
              )}
            </Form.Item>
            {singleUserInput.id === "password" && (
              <div>
                <PasswordValidation
                  minLength={passwordValidations.minLength}
                  hasSpecialAndNumber={passwordValidations.hasSpecialAndNumber}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        ``
      )}
      {singleUserInput.id === "phone" && (
        <>
          <div className={styles["input-icon__title"]}>
            {singleUserInput.svg && (
              <div className={styles["input-icon__title--svg"]}>
                {singleUserInput.svg}
              </div>
            )}
            {
              <label className={styles["input-icon__title--label"]}>
                {singleUserInput.title}{" "}
                {singleUserInput?.required && (
                  <span className="required-color">*</span>
                )}
              </label>
            }
          </div>
          <Form.Item rules={singleUserInput.rules}>
            <Input
              placeholder={singleUserInput.placeHolder}
              size="large"
              className={styles["input-icon__form--input"]}
              onChange={phoneChangeHandler}
              value={num}
              maxLength={14}
            />
            <span className={styles["phoneError"]}>{phoneError}</span>
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default InputWithLabelAndSvg;
