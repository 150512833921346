import { Button, Checkbox, Form } from "antd";
import { InputWithLabelAndSvg } from "components/Global";
import { FC } from "react";
import styles from "./index.module.scss";
import { LoginLayoutBodyProps } from "./types";
import { Link, useNavigate } from "react-router-dom";

// Login layout body designing
const LoginLayoutBody: FC<LoginLayoutBodyProps> = (props) => {
  // Inits
  const {
    title,
    description,
    formData: loginFields,
    buttonTitle,
    redirectText,
    redirectUrl,
    onSubmit,
    btnIntuit,
    btnXero,
    rememberMe,
    isLoading,
    isIntuitLoading,
    isXeroLoading,
    accountText,
    accountUrl,
    setIsPasswordValidate,
  } = props;

  // Initialize navigation
  const navigate = useNavigate();

  // JSX
  return (
    <div className={styles["login-body"]}>
      <Form
        className={styles["login-body__wrapper"]}
        name="basic"
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        initialValues={{
          rememberMe: false, // Set default value for rememberMe
        }}
      >
        <div className={styles["login-body__top"]}>
          <h4 className={styles["login-body__top--title"]}>{title}</h4>
          {description && (
            <div className={styles["login-body__top--description"]}>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}
        </div>
        <div className={styles["login-body__center"]}>
          {loginFields.map((singleUserInput, key) => {
            return (
              <InputWithLabelAndSvg
                key={key}
                singleUserInput={singleUserInput}
                setIsPasswordValidate={setIsPasswordValidate}
              />
            );
          })}
        </div>
        <div className={styles["login-body__remberme-forgot"]}>
          {rememberMe && (
            <Form.Item name="rememberMe" valuePropName="checked">
              <Checkbox
                className={styles["login-body__remberme-forgot--remeber-me"]}
              >
                {rememberMe}
              </Checkbox>
            </Form.Item>
          )}

          <p
            className={styles["login-body__remberme-forgot--forgot-password"]}
            onClick={() => navigate(`${redirectUrl}`)}
          >
            {redirectText}
          </p>
        </div>

        <div className={styles["login-body__end"]}>
          <Button
            type="primary"
            className={`${styles["login-body__end--button"]} ${
              isLoading && "pointer-event-none"
            }`}
            size="large"
            htmlType="submit"
            // disabled={isLoading}
          >
            {isLoading ? (
              <img
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                height={40}
                crossOrigin={
                  process.env.REACT_APP_ENV === "local"
                    ? undefined
                    : "anonymous"
                }
              />
            ) : (
              <>{buttonTitle}</>
            )}
          </Button>
        </div>
        <div className={styles["login-body__signin-options"]}>
          {btnIntuit && (
            <Button
              type="primary"
              className={`${
                styles["login-body__signin-options--button-intuit"]
              } ${isLoading && "pointer-event-none"}`}
              size="large"

              // disabled={isLoading}
            >
              {isIntuitLoading ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  height={40}
                  crossOrigin={
                    process.env.REACT_APP_ENV === "local"
                      ? undefined
                      : "anonymous"
                  }
                />
              ) : (
                <>{btnIntuit}</>
              )}
            </Button>
          )}

          {btnXero && (
            <Button
              className={`${
                styles["login-body__signin-options--button-xero"]
              } ${isLoading && "pointer-event-none"}`}
              size="large"
            >
              {isXeroLoading ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  height={40}
                  crossOrigin="anonymous"
                />
              ) : (
                <>
                  <img
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/images/xero-logo.png`}
                    alt=""
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                  />
                  {btnXero}
                </>
              )}
            </Button>
          )}
        </div>
        {accountText && accountUrl && (
          <div className={styles["login-body__account-text"]}>
            <div className={styles["login-body__account-text--text"]}>
              {accountText}
            </div>
            <Link
              to={"/register"}
              className={styles["login-body__account-text--link"]}
            >
              {accountUrl}
            </Link>
          </div>
        )}
      </Form>
    </div>
  );
};

export default LoginLayoutBody;
