import { FC } from "react";
import styles from "./register.module.scss";
import { Row, Col, Image } from "antd";
import { RegisterLayoutInterface } from "./types";

// Register layout component
const regitserLayout: FC<RegisterLayoutInterface> = (props) => {
  const { children } = props;
  return (
    <div className={styles["register"]}>
      <Row
        className={styles["register__wrapper"]}
        justify={"space-between"}
        align={"middle"}
      >
        <Col className={styles["register__layout"]} span={11}>
          <Image
            className={styles["register__layout--image"]}
            src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/images/register-image.png`}
            preview={false}
            crossOrigin={
              process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
            }
            alt="group"
          />
        </Col>
        <Col className={styles["register__details"]} span={13}>
          <div className={styles["register__details--logo"]}>
            <img
              src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/images/animal_planet_logo.png`}
              crossOrigin={
                process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
              }
              alt="group"
            />
          </div>
          <div className={styles["register__details--body"]}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default regitserLayout;
