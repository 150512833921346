import {
  Loader,
  SideDrawerWrapper,
  TableActionHeader,
} from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { roleColumns } from "constants/Data";
import {
  ChangeEvent,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRoleActionTable,
  getRoleActionTable,
  // paginateRoleTable,
} from "redux/action/roleTableAction";
import { getPermissionsAction } from "redux/action/permissionAction";
import { AppDispatch } from "redux/store";
import { AddSvg } from "utils/svgs";
import AddRoleBody from "./AddRoleBody";
import PermissionBody from "./PermissionBody";
import DynamicTable from "./Table";
import styles from "./index.module.scss";
import { userCheck } from "redux/slice/userCheckSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfileInterface } from "interfaces/user.interface";
import { checkPermission } from "utils/utils";

// Creating the list of role table
const RoleTable = () => {
  const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [sort, setSort] = useState("asc");
  const [isPermissionDrawerOpen, setPermissionSideDrawerOpen] =
    useState<boolean>(false);
  const [filteredData, setFilterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  const [drawerInfo, setDrawerInfo] = useState({
    drawerTitle: "Add Role",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSelectedRole, setEditSelectedRole] = useState<any>();
  const [sortDirection, SetsortDirection] = useState("ascend");
  const [PageSize, setPageSize] = useState(10);

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const navigate = useNavigate();

  // useSelector
  const selectedCompanyPermission = useSelector(
    (state: UserProfileInterface) =>
      state?.userProfile?.data?.companies[0]?.role?.permissions
  );

  const { fistTimeFetchLoading } = useSelector(
    (state: any) => state?.roleTable
  );
  const roleFetch = useSelector((state: any) => state?.roleTable?.isLoading);

  const { data: permissions, isLoading } = useSelector(
    (state: any) => state.permissions
  );

  //To get total records
  const totalRecords = useSelector((state: any) => state?.roleTable?.count);

  const selectedCompany = useSelector(
    (state: any) => state?.userProfile?.data?.companies
  );

  const roleData = useSelector((state: any) => state?.roleTable?.data);

  const PermssionForSelectedCompanay = selectedCompany?.map((data: any) => {
    if (data.companyId === localStorage.getItem("companyId")) {
      return data.role.permissions;
    }
  });

  // Logic for permissions
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: "Roles",
    permission: ["view"],
  });

 // Formatting roleData and setting it in state
useEffect(() => {
  if (roleData?.length === 0 && currentPage > 1) {
    dispatch(
      getRoleActionTable({
        url: `page=${currentPage - 1}&limit=${PageSize}&search=${searchValue}&sort=${sort}`,
        limit: PageSize,
      })
    ).then((res: any) => {
      if (res?.payload?.error?.status === 401) {
        const response = {
          isLoggedIn: true,
          isErrorMessage: res?.payload?.error?.message,
        };
        dispatch(userCheck(response));
      }
    });
    setCurrentPage(currentPage-1)
  }

  // Transform roleData and set it in state
  const formattedRoles = roleData?.map((role: any) => ({
    id: role.id,
    name: role.roleName,
    description: role.roleDescription,
    status: role.status,
    isAdmin: role.isAdminRole,
  }));
  setFilterData(formattedRoles);
}, [roleData]);


  useEffect(() => {
    // Initialize page size from local storage
    const pageSizeFromLocalStorage = localStorage.getItem("roleTableSize");
    if (pageSizeFromLocalStorage !== null) {
      setPageSize(parseInt(pageSizeFromLocalStorage));
    }
  }, []);

  useEffect(() => {
    // Build the URL string based on filterValue
    let url = `page=${currentPage}&limit=${PageSize}&search=${searchValue}&sort=${sort}`;
    if (filterValue !== "all") {
      url += `&filter=${filterValue === "active"}`;
    }
    dispatch(
      getRoleActionTable({
        url,
        isPagination: false,
      })
    ).then((res) => {
      if (res?.payload?.error?.status === 401) {
        const response = {
          isLoggedIn: true,
          isErrorMessage: res?.payload?.error?.message,
        };
        dispatch(userCheck(response));
      }
    });
  }, [PageSize, currentPage, filterValue, searchValue, sort]);

  useEffect(() => {
    // Check permissions and navigate if necessary
    if (
      location.pathname.startsWith("/settings/roles") &&
      selectedCompanyPermission?.length &&
      !hasViewPermission
    ) {
      navigate("/");
    }
  }, [location, selectedCompanyPermission?.length]);

  let RolePermessions;
  if (PermssionForSelectedCompanay) {
    RolePermessions = PermssionForSelectedCompanay[0]?.find((data: any) => {
      return data.permissionName === "Roles";
    });
  }

  // Remove from drawer handler
  const removeDrawerFromDom = () => {
    setSideDrawerOpen(false);
    setPermissionSideDrawerOpen(false);
  };
  // For open the sideDrawer with animation
  const openDrawerHandler = () => {
    setDrawerAnimation(true);
    setSideDrawerOpen(true);
  };
  // For open the sideDrawer with animation
  const openPermissionsHandler = () => {
    setDrawerAnimation(true);
    setPermissionSideDrawerOpen(true);
  };
  // For perform the close animation
  const closeDrawerByAnimation = () => {
    setDrawerAnimation(false);
    setEditSelectedRole(undefined);
  };

  // Handle the pagination for the table
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  // For perform the search operation
  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  // Perform Filter
  const performFilterHandler = (value: any) => {
    setFilterValue(value);
    setCurrentPage(1);
  };

  // For perform the sorting operation
  const performSortHandler = (sortType: string) => {
    if (sortType !== sortDirection) {
      SetsortDirection(sortType);
      setSort(sortType === "ascend" ? "asc" : "desc");
      setCurrentPage(1);
    }
  };

  // To modify the pageSize
  const modifyPageSize = (current: number, size: number) => {
    if (!size) {
      setPageSize(10);
    } else {
      setPageSize(size);
      localStorage.setItem("roleTableSize", size.toString());
      setCurrentPage(1);
    }
  };

  //   For open the model
  const showModal = () => {
    setIsModalOpen(true);
  };

  // For change the data and title between components
  const setDrawerInfoHandler = (drawerTitle: any) => {
    setDrawerInfo({ drawerTitle });
  };

  //   For conform operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const deleteHandler = () => {
    if (editSelectedRole) {
      dispatch(deleteRoleActionTable({ roleId: editSelectedRole?.id })).then(
        (res: any) => {
          if (res?.payload?.error?.status === 401) {
            const response = {
              isLoggedIn: true,
              isErrorMessage: res?.payload?.error?.message,
            };
            dispatch(userCheck(response));
          }
          setEditSelectedRole(undefined);
          setIsModalOpen(false);
        }
      );
    }
  };

  //   For cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // For fetch the all permission of some role
  const fetchRolePermissions = (permissionDetails: any) => {
    setSelectedRole(permissionDetails);
    dispatch(getPermissionsAction(permissionDetails.id)).then((res: any) => {
      if (res?.payload?.error?.status === 401) {
        const response = {
          isLoggedIn: true,
          isErrorMessage: res?.payload?.error?.message,
        };
        dispatch(userCheck(response));
      }
    });
  };

  return (
    <>
      <div className={styles["role-table"]}>
        {!fistTimeFetchLoading ? (
          <>
            <TableActionHeader title={"Roles"}>
              <div className={styles["role-table__action"]}>
                {localStorage.getItem("companyId") !== "undefined" &&
                  RolePermessions?.add && (
                    <button
                      className={`btn-blue ${styles["role-table__action--button"]}`}
                      onClick={() => {
                        openDrawerHandler();
                        setDrawerInfoHandler("Add Role ");
                      }}
                    >
                      <AddSvg />
                      <p>Add Roles</p>
                    </button>
                  )}
              </div>
            </TableActionHeader>
            <div>
              <DynamicTable
                roleDataSource={filteredData}
                roleColumns={roleColumns}
                paginationChangeHandler={paginationChangeHandler}
                currentPage={currentPage}
                totalRecords={totalRecords}
                performSearchHandler={performSearchHandler}
                searchValue={searchValue}
                showModal={showModal}
                openDrawerHandler={openDrawerHandler}
                setDrawerInfoHandler={setDrawerInfoHandler}
                openPermissionsHandler={openPermissionsHandler}
                setEditSelectedRole={setEditSelectedRole}
                fetchRolePermissions={fetchRolePermissions}
                performSortHandler={performSortHandler}
                performFilterHandler={performFilterHandler}
                filterValue={filterValue}
                permissions={RolePermessions}
                PageSize={PageSize}
                modifyPageSize={modifyPageSize}
                sortDirection={sortDirection}
                isLoading={roleFetch}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
      />
      {isSideDrawerOpen && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={drawerInfo.drawerTitle}
          position="right"
          width="half"
        >
          <AddRoleBody
            closeDrawerByAnimation={closeDrawerByAnimation}
            editSelectedRole={editSelectedRole}
            setEditSelectedRole={setEditSelectedRole}
          />
        </SideDrawerWrapper>
      )}
      {isPermissionDrawerOpen && !isLoading && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={drawerInfo.drawerTitle}
          position="right"
          width="half"
        >
          {permissions.length > 0 && (
            <PermissionBody
              closeDrawerByAnimation={closeDrawerByAnimation}
              permissions={permissions}
              selectedRole={selectedRole}
            />
          )}
        </SideDrawerWrapper>
      )}
    </>
  );
};

export default RoleTable;
