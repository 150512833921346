import { createSlice } from "@reduxjs/toolkit";
import { netSuiteAction } from "redux/action/netSuiteAction";

const initialState: any = {
  data: null,
  isLoading: false,
  error: null,
};

const NetSuiteSlice = createSlice({
  name: "netsuite",
  initialState,
  reducers: {
    netSuiteHandler: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(netSuiteAction.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(netSuiteAction.fulfilled, (state: any, action: any) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(netSuiteAction.rejected, (state: any) => {
      state.isLoading = false;
    });
  },
});

export default NetSuiteSlice;
