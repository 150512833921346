import { createSlice } from "@reduxjs/toolkit";
import { deleteConnection, getActiveConnectionAction } from "redux/action/activeConnectionsActions";

// Define the initial state for the slice
const initialState: any = {
  data: null,
  deleteApiData: null,
  isLoading: false,
  error: null,
};

// Create the ActiveConnectionSlice using createSlice
const ActiveConnectionSlice = createSlice({
  name: 'activeConnections',
  initialState,
  reducers: {
    // Handler for setting active connections data
    getActiveConnectionsHandler: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the pending state of the getActiveConnectionAction
    builder.addCase(getActiveConnectionAction.pending, (state: any) => {
      state.isLoading = true;
    });
    // Handle the fulfilled state of the getActiveConnectionAction
    builder.addCase(getActiveConnectionAction.fulfilled, (state: any, action: any) => {
      state.data = action.payload;
      state.isLoading = false;
    });
     // Handle the rejected state of the getActiveConnectionAction
    builder.addCase(getActiveConnectionAction.rejected, (state: any, action: any) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    // Handle the pending state of the deleteConnection
    builder.addCase(deleteConnection.pending, (state:any) => {
      state.isLoading = true;
    });

    // Handle the fulfilled state of the deleteConnection
    builder.addCase(deleteConnection.fulfilled, (state:any, action:any) => {
      state.deleteApiData = action.payload;
      state.isLoading = false;
    });

    // Handle the rejected state of the deleteConnection
    builder.addCase(deleteConnection.rejected, (state:any, action:any) => {
      state.isLoading = false;
      state.error = action.payload?.response;
    });
  }
});


export default ActiveConnectionSlice;
