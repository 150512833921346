import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUnifiedApi } from "redux/apis";

export const getAccountsAction = createAsyncThunk(
  "accounts/getAccount",
  async (data: any, { rejectWithValue }) => {
    const { params, headers } = data;
    try {
      const response = await getUnifiedApi(
        `/accounting/v1/accounts`,
        params,
        headers
      );
      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
