/* eslint-disable react-hooks/rules-of-hooks */
import { Space, Switch, Table } from "antd";
import { FC } from "react";
import {
  DeleteActionSvg,
  EditActionSvg,
  SortSvgBottom,
  SortSvgTop,
} from "utils/svgs";
import SearchAndFilter from "../SearchAndFilter/index";
import "./index.scss";
import { DynamicTableProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import { editUserAction, getUsersAction } from "redux/action/userAction";
import { checkPermission } from "utils/utils";
import { pageSizeOptionsPaging } from "utils/constant";
import { userCheck } from "redux/slice/userCheckSlice";
// import {  pageSizeoptionsPaging } from 'utils/constant';

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const DynamicTable: FC<DynamicTableProps> = (props) => {
  // Inits
  const { Column } = Table;
  const {
    userDataSource,
    performSearchHandler,
    performFilterHandler,
    searchValue,
    filterValue,
    showModal,
    openDrawerHandler,
    setDrawerInfoHandler,
    setEditSelectedUser,
    performSortHandler,
    totalRecords,
    currentPage,
    PageSize,
    modifyPageSize,
    paginationChangeHandler,
    permissions,
    sortDirection,
    // isLoading,
  } = props;
  const dispatch = useDispatch<AppDispatch>();

  // Select user permissions
  const selectedCompanyPermission = useSelector(
    (state: any) => state?.userProfile?.data?.companies[0]?.role?.permissions
  );

  // Check edit user permissions
  const isEditUserPermission = checkPermission(selectedCompanyPermission, {
    permissionName: "Users",
    permission: ["edit"],
  });

  // Edit user data handler
  const editDataHandler = (userObject: any) => {
    openDrawerHandler();
    setDrawerInfoHandler("Edit User");
    setEditSelectedUser(userObject);
  };

  // Delete user data handler
  const deleteDataHandler = (userObject: any) => {
    setEditSelectedUser(userObject);
  };

  // Handle user status change
  const statusHandler = async (value: any, data: any) => {
    const finalData: any = {
      roleId: data?.roleId,
      userId: data?.userId,
      status: value,
      isChangeStatus: true,
    };

    try {
      await dispatch(editUserAction(finalData));

      const query: any = {
        page: currentPage,
        limit: PageSize,
        search: searchValue,
        filter: filterValue === "active",
        sort: "firstName",
        type: sortDirection === "ascend" ? "asc" : "desc",
      };

      if (filterValue === "all") {
        delete query?.filter;
      }

      const res: any = await dispatch(getUsersAction(query));

      if (res?.payload?.error?.status === 401) {
        const response = {
          isLoggedIn: true,
          isErrorMessage: res?.payload?.error?.message,
        };
        dispatch(userCheck(response));
      }
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };

  // For handle the table change click
  const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
    performSortHandler &&
      performSortHandler(columnInfo.order, pageInfo.current);
  };

  // JSX
  return (
    <div className={"dynamic-table"}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        performFilterHandler={performFilterHandler}
        filterValue={filterValue}
        PageSize={PageSize}
      />
      <Table
        dataSource={userDataSource}
        scroll={{ y: "calc(70vh - 190px)", x: "63vh" }}
        pagination={{
          total: totalRecords,
          current: currentPage,
          onChange: paginationChangeHandler,
          pageSize: PageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
          onShowSizeChange: modifyPageSize,
        }}
        // loading={isLoading}
        className="table-global"
        onChange={tableChangeHandler}
      >
        <Column
          title="User Name"
          dataIndex="name"
          key="name"
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          className="bg-white"
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Email Address"
          dataIndex="email"
          key="email"
          className="bg-white"
        />
        <Column
          title="Phone Number"
          dataIndex="phone"
          key="phone"
          className="bg-white"
        />
        <Column
          title="Role"
          dataIndex="role"
          key="role"
          className="bg-white"
          width={"15%"}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"10%"}
          render={(value: any, data: any) => {
            return (
              <Switch
                key={Math.random()}
                defaultChecked={value}
                disabled={
                  data?.isCompanyAdmin || !data.roleStatus || !permissions?.edit
                }
                onChange={(e) => statusHandler(e, data)}
              ></Switch>
            );
          }}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"15%"}
          render={(values: any, data: any) => {
            return (
              <Space size={20}>
                {!(data.isCompanyAdmin || data.isAdmin) ? (
                  <>
                    {isEditUserPermission ? (
                      <div
                        className="cursor-pointer flex align-center justify-center"
                        onClick={() => editDataHandler(data)}
                        style={{ marginRight: "2rem" }}
                      >
                        <EditActionSvg />
                      </div>
                    ) : (
                      <div
                        className={"dynamic-table__no-action"}
                        style={{ marginRight: "2rem" }}
                      >
                        <EditActionSvg />
                      </div>
                    )}
                    {permissions?.delete ? (
                      <div
                        style={{ marginRight: "2rem" }}
                        className="cursor-pointer flex align-center justify-center"
                        onClick={() => {
                          deleteDataHandler(data);
                          showModal();
                        }}
                      >
                        <DeleteActionSvg />
                      </div>
                    ) : (
                      <div
                        style={{ marginRight: "2rem" }}
                        className={"dynamic-table__no-action"}
                      >
                        <DeleteActionSvg />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{ marginRight: "2rem" }}
                      className={"dynamic-table__no-action"}
                    >
                      <EditActionSvg />
                    </div>
                    <div
                      style={{ marginRight: "2rem" }}
                      className={"dynamic-table__no-action"}
                    >
                      <DeleteActionSvg />
                    </div>
                  </>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default DynamicTable;
