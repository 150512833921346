import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import { getApi } from "redux/apis";
import { v4 as uuidv4 } from "uuid";
import { toastText } from "utils/utils";

const CRMCallbackComponent = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [queryParams] = useSearchParams();

  const location = useLocation();

  useEffect(() => {
    getTokenInfo();
  }, []);

  const getTokenInfo = async () => {
    setIsLoading(true);

    const callbackData = {
      uuid: uuidv4(),
      data: true,
      error: false,
    };
    // salesforce callback
    if (location.pathname.startsWith("/force/callback")) {
      try {
        const res: any = await getApi("/force/callback", {
          code: queryParams.get("code"),
        });
        if (res?.status === 200) {
          localStorage.setItem(
            "callBackUpdatedData",
            JSON.stringify(callbackData)
          );
          toastText("Connection Successful", "success");
        }
      } catch (error: any) {
        if (error?.response?.status === 409) {
          // setting error message in localStorage
          localStorage.setItem(
            "callBackUpdatedData",
            JSON.stringify({
              ...callbackData,
              error: true,
              message: error?.response?.data?.error?.message,
            })
          );
        } else {
          toastText("Fail to Connect", "error");
          localStorage.setItem(
            "callBackUpdatedData",
            JSON.stringify({ ...callbackData, error: true })
          );
        }
      }
    }

    window.close();
  };

  return (
    <div className={styles["main-container"]}>
      {isLoading && (
        <img
          src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
          height={60}
          crossOrigin={
            process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
          }
        />
      )}
    </div>
  );
};

export default CRMCallbackComponent;
