import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import { postApi } from "redux/apis";
import { v4 as uuidv4 } from "uuid";
import { toastText } from "utils/utils";

const PaymentGatewayCallbackComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const location = useLocation();

  // Define an object to store callback-related data
  const callbackData = {
    uuid: uuidv4(),
    data: true,
    error: false,
  };

  // Define a function to handle API calls and error handling
  const handleApiCall = async (apiEndpoint: any, data: any) => {
    try {
      // Make an API request
      const res = await postApi(apiEndpoint, data);

      // Check if the response status is 200
      if (res?.status === 200) {
        // Store the callback data in local storage
        localStorage.setItem(
          "callBackUpdatedData",
          JSON.stringify(callbackData)
        );

        // Show a success toast message
        toastText("Connection Successful", "success");
      } else {
        // Throw an error if the API call is not successful
        throw new Error("Fail to Connect");
      }
    } catch (error: any) {
      // Store error data in local storage
      localStorage.setItem("callBackUpdatedData", JSON.stringify({ ...callbackData, error: true, message: error?.response?.data?.message }));
    }
    // Close the window after the API call
    window.close();
  };
  const getTokenInfo = async () => {
    setIsLoading(true);

    if (location.pathname.startsWith("/stripe/callback")) {
      try {
        const code = queryParams.get("code");

        // Use handleApiCall function for the POST request
        await handleApiCall(`/stripe/callback?code=${code}`, {});
      } catch (error) {
        toastText("Fail to Connect", "error");
        localStorage.setItem(
          "callBackUpdatedData",
          JSON.stringify({ ...callbackData, error: true })
        );
      }
    }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  return (
    <div className={styles["main-container"]}>
      {isLoading && (
        <img
          src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
          height={60}
          crossOrigin={
            process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
          }
        />
      )}
    </div>
  );
};

export default PaymentGatewayCallbackComponent;
