/* eslint-disable react-hooks/rules-of-hooks */
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";
import { UserProfileInterface } from 'interfaces/user.interface';
import { FC, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { integrationsERPCards } from "utils/staticObject";
import { checkPermission } from 'utils/utils';
const ERPTable: FC = () => {

  const location = useLocation();

  const navigate = useNavigate();

   // Select user's company permissions
  const selectedCompanyPermission = useSelector((state: UserProfileInterface) => state?.userProfile?.data?.companies[0]?.role?.permissions)

  // Check if user has permission to view Integrations
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: 'Integrations',
    permission: ['view'],
  });

  useEffect(() => {
     // Redirect to the home page if the path starts with '/settings/integrations'
    if (location.pathname.startsWith('/settings/integrations') && selectedCompanyPermission?.length) {
      if (!hasViewPermission) {
        navigate('/')
      }
    }

  }, [location, selectedCompanyPermission?.length]);

  // Render the DynamicCategoryTable component
  return <DynamicCategoryTable dataSource={integrationsERPCards} type={integrationsERPCards[0]?.type} />;
};

export default ERPTable;
